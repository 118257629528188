import { api, builder } from "../api";

import type { ValoresTotais, OficinaCadastro } from "./oficinas.d";
import { Oficinas } from "./oficinas";

const getDadosCadastro = async (user_id: number): Promise<OficinaCadastro | Error> => {

  try {
    const { data } = await api.get(
      builder(`oficina/${user_id}/detalhes`),
    );

    if (data) {
      localStorage.setItem("app-oficinas__oficina", JSON.stringify(data?.data));
      return data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {
  
    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }
};

const getValoresReparos = async (id_oficina: number, user_id: number): Promise<ValoresTotais | Error> => {
  const dados = {
    user_id,
    id_oficina
  };

  try {
    const { data } = await api.post(
      builder(`oficinas-credenciadas/${dados?.id_oficina}/valores`),
      dados,
    );

    if (data) {
      localStorage.setItem("app-oficinas__valores", JSON.stringify(data?.data));
      return data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {

    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }
};

const searchChamados = async (id: number, termo: string, tipoCheck: string): Promise<Oficinas | Error> => {
  const dados = {
    id_oficina: id,
    termo,
    tipoCheck,
  };

  try {
    const { data } = await api.post(
      builder(`oficinas-credenciadas/${id}/chamado`),
      dados,
    );

    if (data) {
      return data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {

    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }  
};

const setCheckinOut = async (
  type: string,
  id_oficina: number,
  id_reparo: number,
  user_id: number,
): Promise<Oficinas | Error> => {

  const dados = {
    type,
    user_id,
    id_reparo,
    id_oficina
  };
 
  try {
    const { data } = await api.post(
      builder(`oficinas-credenciadas/${id_oficina}/check`),
      dados,
    );

    if (data) {

      return data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {
  
    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }
};

export const OficinasService = {
  getDadosCadastro,
  getValoresReparos,
  searchChamados,
  setCheckinOut,
};
