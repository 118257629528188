import React, { useState } from "react";

import { Alert, Box, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import { Loading } from "../../shared/components";

import {
  AddOrcamento,
  Hodometro,
  EearCarPhoto,
  PhotoCarFront,
} from "./components";

export const Diagnostico: React.FC<{ reparoId: number; userId: number }> = ({
  reparoId = 0,
  userId = 0,
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertMessage, setAlertMessage] = useState<any>({
    message: "",
    severity: "",
  });

  const handleClose = () => {
    setOpen(false);
    setAlertMessage("");
  };

  return (
    <>
      <Box width="100%" display="flex">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Hodometro idReparo={reparoId} userId={userId} />
          <EearCarPhoto idReparo={reparoId} userId={userId} />
          <PhotoCarFront idReparo={reparoId} userId={userId} />
          <AddOrcamento idReparo={reparoId} />
        </Grid>
      </Box>
      {isLoading && <Loading open={isLoading} onClick={handleClose} />}
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          key={"top" + "center"}
        >
          <Alert
            onClose={handleClose}
            severity={isAlertMessage?.severity}
            variant="filled"
            sx={{
              width: "100%",
              whiteSpace: "unset",
            }}
          >
            {isAlertMessage.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
