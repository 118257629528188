import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";

import { AuthService } from "../services/auth/AuthService";
import Cookies from "js-cookie";
interface AuthContextData {
  accessToken: string;
  isAuthenticated: boolean;
  signIn: (email: string, password: string) => Promise<string | void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string>("");

  const signIn = useCallback(async (email: string, password: string) => {
    const response = await AuthService.auth(email, password);

    if (response instanceof Error) {
      return response.message;
    }
    setToken(response.access_token);
  }, []);

  const signOut = () => {
    localStorage.clear();
    Cookies.remove("app-oficinas__Token");
  };

  const isAuthenticated = useMemo(() => !!token, [token]);
  const accessToken = useMemo(() => token, [token]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
