import * as React from "react";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import Typography from "@mui/material/Typography";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";

import type { HistoricoReparoProps } from "./HistoricoReparoProps.types";

export const HistoricoReparo = ({
  checkIn,
  checkOut,
  dataProblema,
}: HistoricoReparoProps) => {
  return (
    <Timeline
      sx={{
        m: 0,
        p: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {checkOut?.data_check && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success">
              <DoneAllIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: "success.main" }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="subtitle2" fontWeight={700}>
              Entrega do veículo:
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "flex-start",
                color: "gray",
                fontWeight: 900,
              }}
              variant="subtitle2"
            >
              <EventAvailableIcon sx={{ fontSize: "20px" }} />{" "}
              {checkOut?.data_check}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      )}

      {!!checkIn &&
        checkIn?.map((check: { data_check: string | null }, index: number) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot color="info">
                <CarRepairIcon />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "info.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="subtitle2">Entrada do veículo:</Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  color: "gray",
                }}
                variant="subtitle2"
              >
                <EventAvailableIcon sx={{ fontSize: "20px" }} />{" "}
                {check.data_check}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}

      {dataProblema && (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="warning">
              <SupportAgentOutlinedIcon />
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: "warning" }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="subtitle2">Abertura do chamado:</Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "flex-start",
                color: "gray",
              }}
              variant="subtitle2"
            >
              <EventAvailableIcon sx={{ fontSize: "20px" }} /> {dataProblema}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  );
};
