import * as React from "react";
import { Stack, Chip } from "@mui/material";

import type { Reparos } from "../../../shared/services/orcamento/orcamentos";

export const StatusReparo = ({ sta_nome = "", sta_cor = "gray" }: Reparos) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: 0, mb: 2, bgcolor: "transparent" }}
    >
      <Chip
        label={sta_nome}
        sx={{
          bgcolor: sta_cor,
          fontSize: "0.9rem",
          color: "white",
          px: 2,
          textTransform: "uppercase",
        }}
        component="span"
      />
    </Stack>
  );
};
