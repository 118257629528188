import Cookies from "js-cookie";
import { api, builder } from "../api";
import config from "../../config";


import type { IAuth } from "./auth";

const { bff: { clientId, clientSecret, grantType, scope } } = config;

const auth = async (email: string, password: string): Promise<IAuth | Error> => {
  const dataAuth = {
    grant_type: grantType,
    client_id: clientId,
    client_secret: clientSecret,
    username: email,
    password: password,
    scope
  };

  try {
    const { data: response } = await api.post("/oauth/token", dataAuth);

    if (response) {
      Cookies.set("app-oficinas__Token", response?.access_token);

      await api.get(
        builder("auth/me")
      ).then((res) => {
        localStorage.setItem("app-oficinas__user", JSON.stringify(res.data));
      }).catch((error) => Promise.reject(error?.message));

      return response;
    }

    return Promise.reject(new Error('Missing token'));
  } catch (error) {
    return Promise.reject(new Error((error as { message: string }).message || "Erro no login."));
  }
};

export const AuthService = {
  auth,
};

