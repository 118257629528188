import { api } from '../api';

import type { Orcamento, Reparos } from './orcamentos';

export const getOrcamentos = async <T>({
  repa_id
}: Reparos) => {
  return (await api
    .get(`orcamentos/reparo/${repa_id}`,
      {
        params: { repa_id },
      })
    .then((res) => res.data)) as Orcamento;
};
