import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Reparos } from "../../services/orcamento/orcamentos";

interface ListaOrcamentosProps {
  data?: Reparos;
  queryUrl?: string;
  isShow?: boolean;
  isDisabled?: boolean;
  onChange?: (texto: string) => void;
  onClick?: () => void;
}

export const formattedDay = (data: string) => {
  const currentDate = new Date();
  const formatDate = new Date(data);
  const diffInTime = Math.abs(formatDate.getTime() - currentDate.getTime());
  const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
  const singularTextDay = diffInDays.toString().length > 1 ? "dias" : "dia";

  return diffInDays + " " + singularTextDay;
};

export const ListaOrcamentos: React.FC<ListaOrcamentosProps> = ({
  data,
  queryUrl,
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", bgcolor: "trasparent" }} key={data?.repa_id}>
      <Box
        sx={{ my: 3, mx: 0 }}
        onClick={() =>
          navigate(
            `/orcamentos/${queryUrl ? queryUrl : "gestauto"}/${
              data?.repa_id
            }/detalhes`,
            {
              replace: true,
            }
          )
        }
      >
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ color: data?.sta_cor }}
            >
              {data?.repa_placa}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              gutterBottom
              variant="body1"
              component="div"
              sx={{ color: data?.sta_cor }}
            >
              {formattedDay(data?.repa_data_sinistro || "")}
            </Typography>
          </Grid>
        </Grid>
        <Typography sx={{ color: "#000000" }} variant="body2">
          {data?.repa_veiculo}
        </Typography>
      </Box>
      <Divider variant="middle" />
    </Box>
  );
};
