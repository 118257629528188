import axios from 'axios';
import config from '../config';
import { onError } from './interceptors';
import { handleToken } from '../helpers';


const { bff: { baseUrl } } = config;
const { getToken } = handleToken;

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (request) => {
    request.headers['Authorization'] = `Bearer ${getToken()}`;
    request.headers.Authorization = `Bearer ${getToken()}`;
    return request;
  }, error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => onError(error || error?.message)
);

export const appService = axios.create({
  baseURL: baseUrl
});

export const builder = (resource: string) => {
  return "/api/v1/" + resource;
};
