import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

import { getOrcamentos, getOrcamentoPlaca, updateStatusReparo } from '../services';

import type { Reparos } from "../services/orcamento/orcamentos.d";

export const useOrcamentos = <T>({repa_id}:Reparos) => {
  return {
    ...useQuery({
      queryKey: [repa_id],
      queryFn: () => getOrcamentos<T>({repa_id}),
      staleTime: 2 * 60 * 1000, // tempo de cache
      refetchOnWindowFocus: false,
    }),
  };
};

export const useUpdateStatusReparo = (repa_status: number, repa_ofi_id: number, repa_id: number, flag: boolean) => {
  return {
    ...useQuery({
      queryKey: ['updateStatusReparo'],
      queryFn: () => {
        return updateStatusReparo({ queryKey: [repa_status, repa_ofi_id, repa_id, flag], meta: undefined });
      },
      staleTime: 2 * 60 * 1000, // tempo de cache
      refetchOnWindowFocus: false,
    }),
  };
};

export const useSearchOrcamentosPlaca = (user_id: number, repa_placa: string, flag: boolean) => {
  return {
    ...useQuery({
      queryKey: ['useSearchOrcamentosPlaca'],
      queryFn: () => {
        return getOrcamentoPlaca({ queryKey: [user_id, repa_placa, flag], meta: undefined });
      },
      staleTime: 2 * 60 * 1000, // tempo de cache
      refetchOnWindowFocus: false,
    }),
  };
};
