import { api, appService, builder } from "../api";

import { ReparosDetalhesOrcamento, ReparosOrcamento } from "./orcamentos";
import type { Orcamento } from "./orcamentos";

const getOrcamentos = async (id: number): Promise<Orcamento[] | Error> => {
  const dados = {
    id,
  };  
  try {
    const { data } = await api.post(
      builder(`orcamentos/reparo/${id}`),
      dados
    );

    if (data) {
      return data.data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {

    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }  
};

const setOrcamento = async (repa_id: number, descricao: string, valor: number): Promise<any | Error> => {
  const dados = {
    repa_id: repa_id,
    forn_id: 1,
    orc_vlr_orcado: valor,
    orc_vlr_aprovado: 0,
    orc_descricao: descricao,
    orc_just_recusado: ""
  };

  try {
    const { data } = await api.post(
      builder("orcamento/cadastrar"),
      dados
    );

    if (data) {

      return data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {
    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }  
};

const deleteOrcamento = async (token: string, orc_id: number): Promise<any | Error> => {
  try {
    const { data } = await appService.delete(
      builder("orcamento/remover/" + orc_id),
      { 
        headers:
          {
            "Authorization": `Bearer ${token}`
          }
      }
    );

    if (data) {

      return data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {
    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }  
};

const getReparosComOrcamento = async (user_id: number, tab?: string): Promise<ReparosOrcamento | Error> => {
  const dados = {
    user_id,
    tab
  };
  try {
    const { data } = await api.post(
      builder("oficinas-credenciadas/orcamentos"),
      dados
    );

    if (data) {
      return data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {

    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }  
};

const getDetalhesOrcamento = async (id: number, user_id: number): Promise<ReparosDetalhesOrcamento | Error> => {
  const dados = {
    user_id,
    id_reparo: id
  };

  try {
    const { data } = await api.post(
      builder(`oficinas-credenciadas/${id}/detalhes`),
      dados
    );

    if (data) {

      return data.data;
    }

    return new Error("Erro ao consultar os dados.");
  } catch (error) {

    return new Error((error as { message: string }).message || "Erro ao consultar os dados.");
  }  
};

export const OrcamentoService = {
  getOrcamentos,
  getDetalhesOrcamento,
  getReparosComOrcamento,
  setOrcamento,
  deleteOrcamento
};
