import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const NavbarCheck = () => {
  const navigate = useNavigate();

  return (
    <List sx={{ width: "100%", bgcolor: "#F2F2F2" }}>
      <ListItem>
        <ListItemButton
          sx={{ justifyContent: "end" }}
          onClick={() => navigate("/check/in", { replace: true })}
        >
          <ListItemText
            primary="Fazer Check-In"
            secondary="Entrada na Oficina"
          />
          <IconButton edge="end" aria-label="acessar">
            <ChevronRightIcon />
          </IconButton>
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton
          sx={{ justifyContent: "end" }}
          onClick={() => navigate("/check/out", { replace: true })}
        >
          <ListItemText
            primary="Fazer Check-Out"
            secondary="Saída da Oficina"
          />
          <IconButton edge="end" aria-label="acessar">
            <ChevronRightIcon />
          </IconButton>
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton
          sx={{ justifyContent: "end" }}
          onClick={() => navigate("/orcamentos", { replace: true })}
        >
          <ListItemText
            primary="Lista de Orçamentos"
            secondary="Peças e Mão de Obra"
          />
          <IconButton edge="end" aria-label="acessar">
            <ChevronRightIcon />
          </IconButton>
        </ListItemButton>
      </ListItem>
    </List>
  );
};
