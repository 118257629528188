import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { Smiley } from "../../shared/components/logo/Smiley";

export const OrcamentoEnviadoSuccesso = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        width="99.9%"
        height="100%"
        component="main"
        sx={{
          backgroundColor: "#36B34A",
          minHeight: "100%",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            minHeight: "100vh",
            margin: "auto",
          }}
        >
          <Box
            flexDirection="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              flexGrow: 1,
              minHeight: "100vh",
            }}
          >
            <Box>
              <Smiley />
            </Box>
            <Box sx={{ my: 5 }}>
              <AccessTimeIcon fontSize="large" sx={{ color: "white" }} />
            </Box>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: "400",
                  color: "#fff",
                  mb: 2,
                }}
              >
                Orçamento enviado com sucesso!
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#fff",
                  mb: 5,
                }}
              >
                Recebemos seu orçamento! O sistema será atualizado com aprovação
                ou negativa em até <strong>72 horas</strong>.
              </Typography>
              <Button
                size="large"
                variant="contained"
                onClick={() => navigate("/dashboard", { replace: true })}
                sx={{
                  color: "#323F4B",
                  width: "100%",
                  fontSize: "1.1rem",
                  backgroundColor: "#fff",
                  mt: 4,
                }}
              >
                Acessar sistema
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
