import React, { useEffect, useState } from "react";
import type { MouseEvent } from "react";

import { styled } from "@mui/material/styles";
import {
  Alert,
  Card,
  CardContent,
  Divider,
  Button,
  IconButton,
  CardHeader,
  CardActions,
  Typography,
  Snackbar,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { ExpandMore } from "./ExpandMore";
import { UploadService } from "../../../shared/services/upload/UploadService";
import { Loading } from "../../../shared/components";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const PhotoCarFront = ({ idReparo = 0, userId = 0 }) => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isAlertMessage, setAlertMessage] = useState<any>({
    message: "",
    severity: "",
  });

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadState = (event: any) => {
    setFiles(event?.target?.files);
    setIsDisabled(!isDisabled);
  };

  const handleUpload = async (event: any) => {
    setIsLoading(!isLoading);
    const formData = new FormData();
    formData.append("diretorio", "dianteira");

    for (let i = 0; i < files.length; i++) {
      formData.append("file[]", files[i], files[i]["name"]);
    }

    await UploadService.uploadFile(idReparo, formData)
      .then((result) => {
        let type = "";
        let message = "";
        if (result instanceof Error || !result?.status) {
          type = "error";
          message = "Erro ao enviar arquivo(s).";
        } else {
          type = "success";
          message = "Sucesso ao enviar arquivo(s).";
        }
        setUploadedFiles(result.data);
      })
      .catch((error) => {
        console.error("Error uploading files: ", error);
      });

    setIsLoading(false);
    setIsDisabled(true);
  };

  const handleRemoveFile = async (event: MouseEvent<HTMLElement> | UIEvent) => {
    const fileName =
      (event.currentTarget as HTMLButtonElement)?.getAttribute("data-file") ||
      "";

    const isRemoveFile = await deleteFile(fileName);

    const items = uploadedFiles.filter((item) => {
      return item["name"] !== fileName;
    });

    if (isRemoveFile) {
      setUploadedFiles(items);
    }

    setIsLoading(false);
  };

  const deleteFile = async (fileName: string) => {
    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("diretorio", "dianteira");
    formData.append("user_id", "" + userId);
    setIsLoading(!isLoading);

    return await UploadService.deleteArquivo(idReparo, formData)
      .then((result) => {
        if (result instanceof Error || !result?.status) {
          setOpen(true);
          setAlertMessage({
            message: result?.message,
            severity: "error",
          });

          return false;
        }

        return true;
      })
      .catch((error) => {
        console.error("Error remove files: ", error);
      });
  };

  return (
    <>
      <Card sx={{ backgroundColor: "#FCFCFD", m: "1rem 0" }}>
        <CardHeader
          sx={{ m: "0 0 1rem 0", p: "1rem 1.5rem 0 1.5rem" }}
          title="Foto do Veículo (Dianteira):"
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpand}
              aria-expanded={expanded}
              aria-label="Mostrar mais"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ py: 0, mt: 0 }}>
            <Typography color="textSecondary" variant="body2" mb={2}>
              Enviar Arquivo (.PNG, .JPEG, .PDF)
            </Typography>
            <List component="div" disablePadding>
              {uploadedFiles?.map((file, index) => {
                const { filename, name } = file;
                return (
                  <>
                    <ListItem
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="remover item"
                          size="small"
                          color="error"
                          data-file={name}
                          onClick={(event: MouseEvent<HTMLElement> | UIEvent) =>
                            handleRemoveFile(event)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={filename} />
                    </ListItem>
                    <Divider color="#EAE9F0" />
                  </>
                );
              })}
            </List>
            {isLoading && (
              <Loading open={isLoading} onClick={() => setOpen(false)} />
            )}
          </CardContent>
          <CardActions>
            <Button
              component="label"
              role={undefined}
              tabIndex={-1}
              startIcon={<AddIcon />}
              size="small"
              sx={{
                ml: "1rem",
              }}
            >
              <VisuallyHiddenInput
                type="file"
                multiple
                onChange={uploadState}
              />
            </Button>
            <Button
              sx={{ ml: "40px", fontSize: ".8rem" }}
              size="small"
              onClick={handleUpload}
              startIcon={<CloudUploadIcon />}
              variant="contained"
              disabled={isDisabled}
            >
              <span>Enviar arquivo(s)</span>
            </Button>
          </CardActions>
        </Collapse>
      </Card>
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={666000}
          onClose={handleClose}
          key={"top" + "center"}
        >
          <Alert
            onClose={handleClose}
            severity={isAlertMessage?.severity}
            variant="filled"
            sx={{
              width: "100%",
              whiteSpace: "unset",
            }}
          >
            {isAlertMessage.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
