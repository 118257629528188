import { QueryFunction } from '@tanstack/react-query';
import { api, builder } from '../api';

export const updateStatusReparo: QueryFunction<Record<PropertyKey, never >> = async ({
  queryKey: [repa_status, repa_ofi_id, repa_id, flag],
}) => {

  const dados = {
    repa_status,
    repa_ofi_id,
    repa_id
  };

  return flag ? await api
    .put(
      builder(`oficinas-credenciadas/reparo/${repa_id}/alterar-status`),
      dados
    )
    .then((res) => res.data) : null;

};
