import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  Grid,
  Icon,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";

import { LayoutBaseDePagina } from "../../shared/layouts";
import { NumberFormat } from "../../shared/helpers";
import { Ellipse } from "../../shared/components";

import { OficinasService } from "../../shared/services/oficinas/OficinasService";
import { FerramentasDaListagem } from "../../shared/components/ferramentas-da-listagem/FerramentasDaListagem";
import { NavbarCheck, TabsValores } from "./components";

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dadosOficina, setDadosOficina] = useState<any>({});
  const [valores, setValores] = useState<any>({});

  useEffect(() => {
    const localUser = localStorage.getItem("app-oficinas__user") || false;
    const localOficina = localStorage.getItem("app-oficinas__oficina");
    const localValores = localStorage.getItem("app-oficinas__valores");
    const isDadosOficina = (!!localOficina && !!localValores) ?? false;

    (async () => {
      if (!!localOficina && !!localValores) {
        setDadosOficina(JSON.parse(localOficina));
        setValores(JSON.parse(localValores));
        setIsLoading(false);
      }

      if (localUser) {
        const dataUser = JSON.parse(localUser);
        if (!isDadosOficina) {
          await handleDadosOficina(dataUser?.users?.id).then((res) => {
            if (res) setIsLoading(false);
          });
        }
      }
    })();
  }, []);

  const handleDadosOficina = async (user_id: number) => {
    const resOficina = await OficinasService.getDadosCadastro(user_id);

    if (resOficina instanceof Error) {
      return;
    }
    const localOficina = localStorage.getItem("app-oficinas__oficina");

    if (localOficina) setDadosOficina(JSON.parse(localOficina));

    let resValores = {};
    if (resOficina) {
      resValores = await OficinasService.getValoresReparos(
        resOficina?.data?.ofi_id,
        user_id
      );
      const localValores = localStorage.getItem("app-oficinas__valores");
      if (localValores) setValores(JSON.parse(localValores));
    }

    return !!resValores;
  };

  return (
    <>
      <LayoutBaseDePagina
        titulo="Dashboard"
        barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
      >
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card
                  sx={{
                    backgroundColor: "#36B34A",
                    maxWidth: "100%",
                    display: "block",
                  }}
                >
                  <CardContent
                    sx={{
                      p: "16px !important",
                    }}
                  >
                    {isLoading ? (
                      <>
                        <Skeleton height={35} />
                        <Skeleton />
                      </>
                    ) : (
                      <Box
                        display="flex"
                        alignSelf="center"
                        justifyContent="left"
                      >
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          align="left"
                        >
                          <Icon
                            sx={{
                              color: "#fff",
                              fontSize: 48,
                            }}
                          >
                            account_circle
                          </Icon>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                          }}
                        >
                          Olá,
                          <Typography
                            sx={{
                              color: "#FAD22E",
                              fontSize: "0.8rem",
                            }}
                          >
                            {dadosOficina?.ofi_fantasia}.
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    display="flex"
                    sx={{ mt: 2, ml: 0, justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{
                        color: "#323232",
                      }}
                    >
                      STATUS DE SINISTRO
                    </Typography>
                    <Typography
                      sx={{
                        color: "#21512",
                        fontSize: "0.7rem",
                      }}
                    >
                      Últimos 12 meses
                    </Typography>
                  </Box>
                </Grid>
                {isLoading && valores ? (
                  <>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Skeleton height={100} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Skeleton height={100} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Skeleton height={100} />
                    </Grid>
                  </>
                ) : (
                  <>
                    <TabsValores slug="aprovados" data={valores} />
                    <TabsValores slug="pendentes" data={valores} />
                    <TabsValores slug="enviados" data={valores} />
                  </>
                )}
              </Grid>
              {isLoading && valores ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Skeleton height={100} />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card
                    sx={{
                      backgroundColor: "#36B34A",
                      maxWidth: "100%",
                      display: "flex",
                      p: "1em",
                      gap: "1rem",
                    }}
                  >
                    <Ellipse />
                    <Box display="block">
                      <Typography sx={{ color: "#fff" }}>
                        Faturamento Aprovado: {""}
                        <br />
                      </Typography>
                      <Typography sx={{ color: "#21512", fontSize: "1.2rem" }}>
                        {NumberFormat(valores["aprovados"]?.total)}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              )}

              {isLoading && valores ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Skeleton height={200} />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card
                    sx={{
                      backgroundColor: "#F2F2F2",
                      maxWidth: "100%",
                      display: "block",
                      mt: "1rem",
                    }}
                  >
                    <NavbarCheck />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </LayoutBaseDePagina>
      <Outlet />
    </>
  );
};
