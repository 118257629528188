import * as React from "react";
import { createBrowserRouter } from "react-router-dom";

import {
  CheckInOut,
  Dashboard,
  Login,
  Logout,
  Orcamentos,
  OrcamentoDetalhes,
  OrcamentoEnviadoSuccesso,
  PageNotFound,
  Welcome,
} from "../pages";

export const routes = createBrowserRouter([
  {
    children: [
      {
        index: true,
        path: "/",
        element: <Welcome />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/diagnostico/:placa/enviado",
        element: <OrcamentoEnviadoSuccesso />,
      },
      {
        path: "/check",
        element: <CheckInOut />,
      },
      {
        path: "/check/:tipo",
        element: <CheckInOut />,
      },
      {
        path: "/check/:tipo/:placa",
        element: <CheckInOut />,
      },
      {
        path: "/404",
        element: <PageNotFound />,
      },
      {
        path: "/orcamentos",
        element: <Orcamentos />,
      },
      {
        path: "/orcamentos/:status",
        element: <Orcamentos />,
      },
      {
        path: "/orcamentos/enviados/:tab",
        element: <Orcamentos />,
      },
      {
        path: "/orcamentos/:status/:id_reparo/detalhes",
        element: <OrcamentoDetalhes />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
]);

export default routes;
