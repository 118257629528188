import { QueryFunction } from '@tanstack/react-query';
import { api, builder } from '../api';

export const getOrcamentoPlaca: QueryFunction<Record<PropertyKey, never >> = async ({
  queryKey: [user_id, repa_placa, flag],
}) => {

  return flag ? await api
    .post(
      builder(`oficinas-credenciadas/orcamentos/search`),
      {
        user_id,
        repa_placa
      },
    )
    .then(res => { return res.data; }): null;
};
