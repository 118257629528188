import { api, appService, builder } from "../api";

const uploadFile = async (id: number, dados: FormData): Promise<any | Error> => {
  try {
    const { data } = await api.post(
      builder(`oficinas-credenciadas/${id}/upload-file`),
      dados,
      { 
        headers:
          {
            "Content-Type": "multipart/form-data"
          }
      }
    );

    if (data) {
      return data;
    }

    return new Error("Erro ao enviar arquivos.");
  } catch (error) {

    return new Error((error as { message: string }).message || "Erro ao enviar os dados.");
  }  
};


const deleteArquivo = async (id_reparo: number | string, dados: FormData): Promise<any | Error> => {
  try {
    const { data } = await api.post(
      builder(`oficinas-credenciadas/${id_reparo}/remove-file`),
      dados,
    );

    if (data) {

      return data;
    }

    return new Error("Erro ao remover arquivo.");
  } catch (error) {
    return new Error((error as { message: string }).message || "Erro ao remover arquivo.");
  }  
};

export const UploadService = {
  deleteArquivo,
  uploadFile
};
