import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Card, Link, Typography } from "@mui/material";

interface TabsValoresProps {
  slug: string;
  data: any;
}

export const TabsValores: React.FC<TabsValoresProps> = ({
  slug = "",
  data = {},
}) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
      <Card
        sx={{
          backgroundColor: "#F2F2F2",
          maxWidth: "100%",
          display: "block",
          p: "1em",
        }}
      >
        <Link
          underline="none"
          onClick={() => navigate(`/orcamentos/${slug}`, { replace: true })}
        >
          <Typography variant="h4" align="center" sx={{ color: "#323232" }}>
            {data[slug]?.qnte < 999 ? data[slug]?.qnte : "+1K"}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            lineHeight="1.1"
            sx={{ color: "#323232" }}
          >
            <small>{data[slug]?.texto}</small>
          </Typography>
        </Link>
      </Card>
    </Grid>
  );
};
