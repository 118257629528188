import React, { useEffect, useState } from "react";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Grid,
  CircularProgress,
  Skeleton,
  Stack,
  Paper,
  Typography,
} from "@mui/material";

import Avatar from "@mui/material/Avatar";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { LayoutBaseDePagina } from "../../shared/layouts";
import { DadosReparo } from "./components";

import { OrcamentoService } from "../../shared/services/orcamento/OrcamentoService";
import { OrcamentoDetalhe } from "../../shared/services/orcamento/orcamentos";
import { NumberFormat } from "../../shared/helpers";

export const OrcamentoDetalhes = () => {
  const [detalhes, setDetalhes] = useState<any>([]);
  const [orcamentos, setOrcamentos] = useState<any>([]);
  const [valorTotal, setValorTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { status, id_reparo } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const localUser = localStorage.getItem("app-oficinas__user") || false;
    const localOficina = localStorage.getItem("app-oficinas__oficina");
    const isDadosOficina = !!localOficina ?? false;

    (async () => {
      if (localUser) {
        const dataUser = JSON.parse(localUser);

        if (isDadosOficina) {
          await handleDetalhes(Number(id_reparo) || 0, dataUser?.users?.id);
        }
      }
    })();
  }, []);

  const handleDetalhes = async (id_reparo: number, user_id: number) => {
    setIsLoading(true);
    OrcamentoService.getDetalhesOrcamento(id_reparo, user_id).then((res) => {
      if (res instanceof Error) {
        setDetalhes([]);
      } else {
        setDetalhes(res?.reparo || {});
        setOrcamentos(res?.orcamentos || []);
        setValorTotal(res?.reparo?.vlr_total || 0);
      }
      setIsLoading(false);
    });
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <LayoutBaseDePagina titulo="Dashboard" barraDeFerramentas={null}>
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              {isLoading ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Skeleton height={52} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Skeleton height={52} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Skeleton height={52} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                    <Skeleton height={36} />
                  </Grid>
                </>
              ) : (
                detalhes && (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <DadosReparo {...detalhes} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ pt: "0 !important" }}
                    >
                      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                        <Paper
                          sx={{
                            my: 0,
                            mx: "auto",
                            px: "1rem",
                            bgcolor: "background.listDefault",
                          }}
                        >
                          <Typography
                            display="flex"
                            variant="h6"
                            sx={{
                              color: "#646464",
                              fontSize: "1.2rem !important",
                              fontWeight: 500,
                              py: 2,
                              mb: 1,
                              justifyContent: "space-between",
                            }}
                          >
                            <Avatar>
                              <HomeRepairServiceIcon />
                            </Avatar>{" "}
                            Orçamento
                            {open ? (
                              <ExpandLess onClick={handleClickOpen} />
                            ) : (
                              <ExpandMore onClick={handleClickOpen} />
                            )}
                          </Typography>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Stack
                              display="flex"
                              flexDirection="column"
                              gap={2}
                              sx={{ py: 2 }}
                            >
                              <Stack
                                display="flex"
                                flexDirection="row"
                                sx={{ justifyContent: "space-between" }}
                              >
                                <Typography component="p" variant="body1">
                                  Descrição
                                </Typography>
                                <Typography component="p" variant="body1">
                                  Valor (R$)
                                </Typography>
                              </Stack>
                              {orcamentos ? (
                                orcamentos.map(
                                  (orcamento: OrcamentoDetalhe) => (
                                    <>
                                      <Stack
                                        display="flex"
                                        flexDirection="row"
                                        sx={{ justifyContent: "space-between" }}
                                        key={orcamento.orc_id}
                                      >
                                        <Typography
                                          component="p"
                                          variant="body2"
                                          sx={{ textTransform: "capitalize" }}
                                        >
                                          {orcamento?.orc_descricao}
                                        </Typography>
                                        <Typography
                                          component="p"
                                          variant="body2"
                                          sx={{
                                            color: "#36B34A",
                                            fontWeight: "900",
                                          }}
                                        >
                                          {NumberFormat(
                                            orcamento?.orc_vlr_orcado
                                          )}
                                        </Typography>
                                      </Stack>
                                    </>
                                  )
                                )
                              ) : (
                                <CircularProgress color="success" />
                              )}
                              {orcamentos && (
                                <Typography
                                  display="flex"
                                  variant="h6"
                                  sx={{
                                    justifyContent: "right",
                                    color: "#36B34A",
                                  }}
                                >
                                  {NumberFormat(valorTotal)}
                                </Typography>
                              )}
                            </Stack>
                          </Collapse>
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "block",
                          width: "100%",
                          "& button": { mt: 2 },
                        }}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() =>
                            navigate(`/orcamentos`, { replace: true })
                          }
                          sx={{
                            color: "rgba(255, 255, 255, 0.8)",
                            background: "rgba(22, 28, 36, 0.48)",
                            width: "100%",
                          }}
                        >
                          Voltar
                        </Button>
                      </Box>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </LayoutBaseDePagina>
      <Outlet />
    </>
  );
};
