import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

interface ChildrenProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const ReactQueryProvider: React.FC<ChildrenProps> = ({
  children,
}): JSX.Element => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
