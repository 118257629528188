import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Agrupamento } from "../../services/orcamento/orcamentos";

interface ListaAgrupamentoProps {
  data?: Agrupamento[];
}

export const ListaAgrupamento: React.FC<ListaAgrupamentoProps> = ({ data }) => {
  const navigate = useNavigate();

  const handleDrawerContext = (slug: string) => {
    navigate(`/orcamentos/enviados/?tab=${slug}`, { replace: true });
    return;
  };

  return (
    <>
      {data?.map((item: any, index) => (
        <>
          {item.total > 0 && (
            <Box sx={{ width: "100%", bgcolor: "trasparent" }} key={index}>
              <Box
                sx={{ my: 3, mx: 0 }}
                onClick={() => handleDrawerContext(item?.slug)}
              >
                <Typography sx={{ color: "#433E5B" }} variant="h6">
                  {item?.titulo}
                </Typography>
                <Grid container alignItems="center" sx={{ mt: "0.9rem" }}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="body2"
                      component="div"
                      sx={{ color: item?.color }}
                    >
                      {item?.total} Orçamentos
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="span"
                      sx={{ color: "#433E5B", fontWeight: 900 }}
                    >
                      {Number(item?.percente)}%
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" />
            </Box>
          )}
        </>
      ))}
    </>
  );
};
