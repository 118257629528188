import * as React from "react";

import {
  Box,
  IconButton,
  Grid,
  Card,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import MessageIcon from "@mui/icons-material/Message";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PollIcon from "@mui/icons-material/Poll";

import { useCopyToClipboard } from "../../../shared/helpers/copy-to-clipboard";
import { StatusReparo } from "./StatusReparo";

import type { Reparos } from "../../../shared/services/orcamento/orcamentos";

export const DadosReparo: React.FC<Reparos> = (reparo) => {
  const [open, setOpen] = React.useState(false);
  const [copiedText, copy] = useCopyToClipboard();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCopy = (text: string) => () => {
    return copy(text)
      .then(() => {
        handleOpen();
        console.log("Copied!", { text });
      })
      .catch((error) => {
        console.error("Failed to copy!", error);
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Card
          sx={{
            backgroundColor: "transparent",
            maxWidth: "100%",
            display: "block",
          }}
        >
          <CardContent
            sx={{
              px: "16px",
              pt: "16px",
              pb: "16px !important",
            }}
          >
            {StatusReparo(reparo)}
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: reparo?.sta_cor,
                fontWeight: "medium",
              }}
            >
              {reparo.repa_placa} {""}
              <Tooltip
                open={open}
                onClose={handleClose}
                title={copiedText ? `Placa copiada!` : `Clique para copiar`}
              >
                <IconButton onClick={handleCopy(`${reparo?.repa_placa}`)}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography sx={{ my: "5px" }} variant="subtitle1">
              {reparo.veiculo}
            </Typography>
            <Grid
              item
              container
              sx={{
                alignItems: "center",
                display: "flex",
                p: 0,
                mb: 0,
              }}
            >
              <Grid item md={12}>
                {reparo?.repa_conclusao && (
                  <>
                    <Typography variant="body2" component="span">
                      Data Conclusão: &nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ color: "green", fontWeight: "900" }}
                    >
                      {reparo.repa_conclusao}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <List
        sx={{
          width: "100%",
          borderRadius: "15px",
          bgcolor: "background.listDefault",
          my: 2,
          py: 2,
        }}
      >
        <ListItem sx={{ py: 0 }} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar>
              <PollIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{
              my: 0,
            }}
            primary="Certificado de Garantia"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    display: "inline",
                    fontSize: 14,
                    color: "#BBBBBB",
                  }}
                >
                  Nº: {reparo.certificado}
                  <br />
                  Emissão: {reparo.data_ativacao}
                </Typography>
              </>
            }
            primaryTypographyProps={{
              fontSize: "1.2rem !important",
              color: "#646464 !important",
              fontWeight: 500,
            }}
          />
        </ListItem>
      </List>
      <List
        sx={{
          width: "100%",
          borderRadius: "15px",
          bgcolor: "background.listDefault",
          my: 2,
          py: 2,
        }}
      >
        <ListItem sx={{ py: 0 }} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{
              my: 0,
            }}
            primary="Proprietário"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    display: "inline",
                    fontSize: 14,
                    color: "#BBBBBB",
                  }}
                >
                  {reparo.proprietario}
                  <br />
                  Km atual do veículo: {reparo?.kma || "-----"} km
                </Typography>
              </>
            }
            primaryTypographyProps={{
              fontSize: "1.2rem !important",
              color: "#646464 !important",
              fontWeight: 500,
            }}
          />
        </ListItem>
      </List>
      <List
        sx={{
          width: "100%",
          borderRadius: "15px",
          bgcolor: "background.listDefault",
          my: 2,
          py: 2,
        }}
      >
        <ListItem sx={{ py: 0 }} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar>
              <MessageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{
              my: 0,
            }}
            primary="Relato do Consumidor"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    display: "inline",
                    fontSize: 14,
                    color: "#BBBBBB",
                  }}
                >
                  {reparo.relato_consumidor}
                </Typography>
              </>
            }
            primaryTypographyProps={{
              fontSize: "1.2rem !important",
              color: "#646464 !important",
              fontWeight: 500,
            }}
          />
        </ListItem>
      </List>
      <List
        sx={{
          width: "100%",
          borderRadius: "15px",
          bgcolor: "background.listDefault",
          my: 2,
          py: 2,
        }}
      >
        <ListItem sx={{ py: 0 }} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar>
              <MessageOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{
              my: 0,
            }}
            primary="Diagnóstico da Oficina"
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    display: "inline",
                    fontSize: 14,
                    color: "#BBBBBB",
                  }}
                >
                  {reparo.laudo_tecnico}
                </Typography>
              </>
            }
            primaryTypographyProps={{
              fontSize: "1.2rem !important",
              color: "#646464 !important",
              fontWeight: 500,
            }}
          />
        </ListItem>
      </List>
    </>
  );
};
