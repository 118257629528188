import { RouterProvider } from "react-router-dom";

import "./shared/forms/TraducoesYup";

import {
  AppThemeProvider,
  AuthProvider,
  DrawerProvider,
  ReactQueryProvider,
} from "./shared/contexts";
// import { MenuLateral } from "./shared/components";
import { routes } from "./routes";

export const App = () => {
  return (
    <AuthProvider>
      <AppThemeProvider>
        <ReactQueryProvider>
          <DrawerProvider>
            {/* <MenuLateral> */}
            <RouterProvider router={routes} />
            {/* </MenuLateral> */}
          </DrawerProvider>
        </ReactQueryProvider>
      </AppThemeProvider>
    </AuthProvider>
  );
};
