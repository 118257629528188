import Cookies from 'js-cookie';

const getToken = () => {
  const token = Cookies.get('app-oficinas__Token');

  if (token) {
    return token;
  }

  return null;
};

const setToken = (token: string) => {
  localStorage.setItem('', token);
  Cookies.set('app-oficinas__Token', token);
};

export const handleToken = {
  getToken,
  setToken,
};
