/* eslint-disable indent */
import { useEffect, useState } from "react";
import {
  Outlet,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Icon,
  TextField,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";

import { TabsComponent } from "../../shared/components/tabs/TabsComponent";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { OrcamentoService } from "../../shared/services";
import {
  ListaAgrupamento,
  ListaOrcamentos,
} from "../../shared/components/lista-orcamentos";
import { useSearchOrcamentosPlaca } from "../../shared/hooks";
import type { ReparosOrcamento } from "../../shared/services/orcamento/orcamentos.d";

enum Disclaimer {
  aprovados = "Total de Orçamentos",
  enviados = "Total de Orçamentos",
  pendentes = "Veículos estão aguardando orçamento",
}

export const Orcamentos = () => {
  const [orcamentos, setOrcamentos] = useState<any>([]);
  const [dadosOficina, setDadosOficina] = useState<any>({});
  const [agrupamento, setAgrupamento] = useState<any>([]);
  const [termoError, setTermoError] = useState("");
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [termo, setTermo] = useState("");
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams<"status" | "id_reparo">();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchPlaca, setSearchPlaca] = useState<any>({
    userId: 0,
    placa: "",
    flag: false,
  });

  const { data, refetch } = useSearchOrcamentosPlaca(
    searchPlaca.userId,
    searchPlaca.placa,
    searchPlaca.flag
  );

  const localDadosOficina =
    localStorage.getItem("app-oficinas__oficina") || "{}";
  const objDadosOficina = JSON.parse(localDadosOficina);

  useEffect(() => {
    const isTabParams = searchParams.has("tab");

    (async () => {
      try {
        setDadosOficina(objDadosOficina);
      } catch (error) {
        navigate(`/dashboard`, { replace: true });
      }

      if (objDadosOficina) {
        return await handleDadosOrcamentos(
          objDadosOficina?.user_id,
          isTabParams ? searchParams.get("tab") || "" : params?.status
        );
      }
    })();
  }, [searchParams]);

  useEffect(() => {
    if (searchPlaca.userId > 0 && searchPlaca.placa !== undefined) {
      refetch();
    }
  }, [searchPlaca, refetch]);

  useEffect(() => {
    if (searchPlaca.userId > 0 && searchPlaca.placa !== undefined) {
      if (searchPlaca.flag && data) {
        const arrayComplexo = Object.keys(data).map((chave) => {
          const valor = data[chave];
          if (typeof valor === "object") {
            return valor;
          }
        });

        setTotal(data?.total || 0);
        setIsLoading(false);
        setOrcamentos(arrayComplexo || {});
      }
    }
  }, [data]);

  const handleDadosOrcamentos = async (user_id: number, tab?: string) => {
    setIsLoading(true);
    await OrcamentoService.getReparosComOrcamento(user_id, tab)
      .then((res) => {
        if (res instanceof Error) {
          setOrcamentos([]);
        } else {
          setTotal(res?.total || 0);
          setAgrupamento(res?.agrupamento || {});
          setOrcamentos(res?.data || {});
        }

        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDiclaimer = (status: string | undefined) => {
    switch (status) {
      case "aprovados": {
        return Disclaimer["aprovados"];
      }
      case "enviados": {
        return Disclaimer["enviados"];
      }
      case "pendentes": {
        return Disclaimer["pendentes"];
      }
      default:
        return "";
    }
  };

  const handleOnChange = (termo: string) => {
    if (termo.length > 4) {
      setIsSearchDisabled(false);
    } else {
      setIsSearchDisabled(true);
    }
    setTermo(termo);
  };

  const handlePesquisar = async () => {
    setIsLoading(true);
    setSearchPlaca({
      userId: dadosOficina?.user_id,
      placa: termo,
      flag: true,
    });
  };

  return (
    <>
      <LayoutBaseDePagina titulo="Dashboard" barraDeFerramentas={null}>
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              {!searchParams.has("tab") && !isLoading && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ mt: "0 !important", pt: "0 !important" }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ m: 0, mt: 2, color: "#2C2646" }}
                  >
                    Orçamentos {params?.status}
                  </Typography>
                  {total > 0 && (
                    <>
                      <Typography
                        variant="h2"
                        align="center"
                        sx={{ m: 0, mt: 2, color: "#000" }}
                      >
                        {total}
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ mt: 0, color: "#908BA6" }}
                      >
                        {getDiclaimer(params?.status)}
                      </Typography>
                    </>
                  )}
                  <Typography
                    align="center"
                    sx={{ m: 0, mt: 2, color: "#2C2646" }}
                    fontSize={"small"}
                  >
                    Resultados do último ano. Para uma pesquisa segmentada, por
                    favor utilizar a busca por placa.
                  </Typography>
                </Grid>
              )}
              {isLoading ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Skeleton height={52} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                </Grid>
              ) : (
                orcamentos && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {searchParams.has("tab") && (
                      <TabsComponent
                        selected={searchParams.get("tab") || ""}
                        tabs={[
                          { name: "Analise", value: "analise" },
                          { name: "Negados", value: "recusados" },
                        ]}
                      />
                    )}
                    {!searchParams.has("tab") &&
                      params?.status !== "enviados" && (
                        <Box
                          padding={1}
                          display="flex"
                          alignItems="center"
                          height={theme.spacing(6)}
                          sx={{
                            borderRadius: "2px !important",
                            my: theme.spacing(2),
                          }}
                        >
                          <TextField
                            fullWidth
                            type="termo"
                            value={termo}
                            placeholder="Buscar por Placa..."
                            disabled={isLoading}
                            error={!!termoError}
                            helperText={termoError}
                            onKeyDown={() => setTermoError("")}
                            onChange={(e) => handleOnChange(e.target.value)}
                            sx={{ mr: 2, borderRadius: "2px !important" }}
                          />
                          <Box flex={1} display="flex" justifyContent="end">
                            <Button
                              variant="contained"
                              disabled={isSearchDisabled}
                              onClick={handlePesquisar}
                              endIcon={<Icon>search</Icon>}
                              sx={{
                                color: "#fff",
                                backgroundColor: "#36B34A",
                                padding: 2,
                                margin: 0,
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    <Card>
                      <CardContent
                        sx={{
                          px: "16px",
                          py: "8px",
                          bgcolor: "#FCFCFD",
                        }}
                      >
                        {agrupamento.length &&
                        params?.status === "enviados" &&
                        !searchParams.has("tab") ? (
                          <ListaAgrupamento data={agrupamento} />
                        ) : (
                          orcamentos?.map((item: any, index: 0) => {
                            if (item?.repa_id) {
                              return (
                                <ListaOrcamentos
                                  data={item}
                                  queryUrl={params?.status}
                                  key={index}
                                />
                              );
                            }
                          })
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
            {!isLoading && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "block",
                    width: "100%",
                    "& button": { mt: 2 },
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => navigate(`/dashboard`, { replace: true })}
                    sx={{
                      color: "rgba(255, 255, 255, 0.8)",
                      background: "rgba(22, 28, 36, 0.48)",
                      width: "100%",
                    }}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </LayoutBaseDePagina>
      <Outlet />
    </>
  );
};
