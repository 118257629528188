import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Icon,
  TextField,
  useTheme,
  CardHeader,
  Snackbar,
} from "@mui/material";

import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UndoIcon from "@mui/icons-material/Undo";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import { ExpandMore } from "./ExpandMore";
import { OrcamentoDetalhe } from "../../../shared/services/orcamento/orcamentos";
import { Loading } from "../../../shared/components";
import { OrcamentoService } from "../../../shared/services/orcamento/OrcamentoService";
import { NumberFormat } from "../../../shared/helpers";
import { useUpdateStatusReparo } from "../../../shared/hooks";

export const AddOrcamento: React.FC<{ idReparo: number }> = ({
  idReparo = 0,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [expandedOrcamento, setExpandedOrcamento] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [descricaoOrcamento, setDescricaoOrcamento] = useState("");
  const [valorOrcamento, setValorOrcamento] = useState(0);
  const [lstOrcamentos, setOrcamentos] = useState<OrcamentoDetalhe[]>([]);
  const [dadosOficina, setDadosOficina] = useState<any>({});
  const [isAlertMessage, setAlertMessage] = useState<any>({
    message: "",
    severity: "",
  });

  const [statusToUpdate, setStatusToUpdate] = useState<any>({
    repa_status: 0,
    repa_ofi_id: 0,
    repa_id: 0,
    flag: false,
  });

  const { isSuccess, isFetching, isError, refetch, isFetched } =
    useUpdateStatusReparo(
      statusToUpdate.repa_status,
      statusToUpdate.repa_ofi_id,
      statusToUpdate.repa_id,
      statusToUpdate.flag
    );

  const navigate = useNavigate();

  useEffect(() => {
    if (idReparo > 0) {
      loadOrcamentos(idReparo);
    }
  }, []);

  useEffect(() => {
    const localOficina = localStorage.getItem("app-oficinas__oficina");

    (async () => {
      if (localOficina) {
        setDadosOficina(JSON.parse(localOficina));
      }
    })();
  }, []);

  useEffect(() => {
    if (statusToUpdate.repa_ofi_id > 0 && statusToUpdate.repa_id > 0) {
      refetch();
    }
  }, [statusToUpdate, refetch]);

  useEffect(() => {
    if (statusToUpdate.repa_ofi_id > 0 && statusToUpdate.repa_id > 0) {
      if (isSuccess && isFetching && isFetched) {
        setStatusToUpdate({
          repa_status: 0,
          repa_ofi_id: 0,
          repa_id: 0,
          flag: false,
        });
        setIsDisabled(true);
        setOpen(true);
        setAlertMessage({
          message:
            "Agradecemos o envio do orçamento! Em breve o técnico da Gestauto entrará em contato!",
          severity: "success",
        });

        navigate(`/diagnostico/${idReparo}/enviado`, { replace: true });
      }
    }
  }, [isSuccess, isFetching, isFetched, isDisabled]);

  const handleExpandOrcamentoClick = () => {
    setExpandedOrcamento(!expandedOrcamento);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadOrcamentos = async (repa_id: number) => {
    setIsLoading(true);
    setIsDisabled(true);

    return await OrcamentoService.getOrcamentos(repa_id).then((result) => {
      if (result instanceof Error) {
        return setOrcamentos([]);
      }

      setOrcamentos(result);
      setIsLoading(false);

      if (result.length > 0) {
        setIsDisabled(false);
      }
    });
  };

  const handleAddItemOrcamento = async () => {
    if (descricaoOrcamento === "" || valorOrcamento === 0) {
      setOpen(true);
      setAlertMessage({
        message:
          "Para adicionar um item de orçamento, favor informar a sua descrição e o seu valor",
        severity: "error",
      });
      return;
    }

    await OrcamentoService.setOrcamento(
      idReparo,
      descricaoOrcamento,
      valorOrcamento
    ).then((result) => {
      let type = "";
      let message = "";

      if (result instanceof Error || !result?.status) {
        type = "error";
        message = "Erro ao sarvar item do orçamento.";

        setOpen(true);
        setIsLoading(false);
      } else {
        type = "success";
        message = "Sucesso ao salvar item do orçamento.";

        setIsLoading(false);
        setOpen(true);
        loadOrcamentos(idReparo);
        setDescricaoOrcamento("");
        setValorOrcamento(0);
      }

      if (result.length > 0) {
        setIsDisabled(false);
      }

      setAlertMessage({
        message: message,
        severity: type,
      });
    });
  };

  const handleFinishCheck = () => {
    setStatusToUpdate({
      repa_status: 9,
      repa_ofi_id: dadosOficina.ofi_id,
      repa_id: idReparo,
      flag: true,
    });

    return;
  };

  const handleDeleteOrcamento = async (orc_id: number) => {
    OrcamentoService.deleteOrcamento("", orc_id).then((result) => {
      let type = "";
      let message = "";
      if (result instanceof Error || !result?.status) {
        type = "error";
        message = "Erro ao deletar item do orçamento.";
        setOpen(true);
        setIsLoading(false);
      } else {
        type = "success";
        message = "Sucesso ao deletar item do orçamento.";
        setIsLoading(false);
        setOpen(true);
        loadOrcamentos(idReparo);
      }
      setAlertMessage({
        message: message,
        severity: type,
      });
    });
  };

  return (
    <>
      {isLoading && <Loading open={isLoading} onClick={() => setOpen(false)} />}
      <Card sx={{ backgroundColor: "#FCFCFD", m: "2rem 0" }}>
        <CardHeader
          sx={{ m: "0 0 1rem 0", p: "1rem 1.5rem 0 1.5rem" }}
          title="Adicionar orçamento:"
          action={
            <ExpandMore
              expand={expandedOrcamento}
              onClick={handleExpandOrcamentoClick}
              aria-expanded={expandedOrcamento}
              aria-label="Mostrar mais"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />
        <Collapse in={expandedOrcamento} timeout="auto" unmountOnExit>
          <CardContent sx={{ py: 0, mt: 0 }}>
            <Typography color="textSecondary" variant="body2" mb={2}>
              Insira as informações referentes as peças utilizadas
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                  <TextField
                    type="desc"
                    size="small"
                    placeholder="Descrição da peça"
                    sx={{ mr: 2, width: "100%" }}
                    value={descricaoOrcamento}
                    onChange={(event: any) => {
                      setDescricaoOrcamento(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={10} md={4}>
                  <TextField
                    type="number"
                    size="small"
                    value={valorOrcamento}
                    placeholder="Valor(R$)"
                    sx={{ mr: 2, width: "100%" }}
                    inputProps={{
                      step: 0.01,
                    }}
                    onChange={(event: any) => {
                      setValorOrcamento(parseFloat(event.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={2} md={1}>
                  <Button
                    variant="contained"
                    startIcon={<Icon>add</Icon>}
                    sx={{
                      color: "#284614",
                      backgroundColor: theme.palette.warning.light,
                    }}
                    onClick={handleAddItemOrcamento}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={2} display="flex" alignItems="center"></Box>
            {lstOrcamentos?.length > 0 && (
              <>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography color="textSecondary" variant="body2">
                    <b>Ítens adicionados: </b>
                  </Typography>
                </Box>
              </>
            )}
            {lstOrcamentos?.map((orcamento: OrcamentoDetalhe) => (
              <Box
                sx={{
                  mb: 1,
                  p: "2px",
                }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                bgcolor="#e9e9e9"
                key={orcamento.orc_id}
              >
                <Typography
                  sx={{
                    display: "flex",
                    textTransform: "capitalize",
                    alignItems: "flex-start",
                    fontSize: "0.9rem",
                  }}
                >
                  {orcamento.orc_descricao}
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    textTransform: "capitalize",
                    alignItems: "flex-start",
                    fontSize: "0.9rem",
                  }}
                >
                  {NumberFormat(orcamento.orc_vlr_orcado)}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Collapse>
      </Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        <Button
          size="medium"
          variant="contained"
          startIcon={<UndoIcon />}
          onClick={() => navigate("/dashboard", { replace: true })}
          sx={{
            color: "#fff",
            fontSize: "1rem",
            backgroundColor: "#666666",
          }}
        >
          Cancelar
        </Button>
        <Button
          size="medium"
          variant="contained"
          disabled={isDisabled}
          onClick={handleFinishCheck}
          startIcon={<DoneAllIcon />}
          sx={{
            color: "#fff",
            fontSize: "1rem",
            backgroundColor: "#36B34A",
          }}
        >
          Finalizar
        </Button>
      </Box>
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          key={"top" + "center"}
        >
          <Alert
            onClose={handleClose}
            severity={isAlertMessage?.severity}
            variant="filled"
            sx={{
              width: "100%",
              whiteSpace: "unset",
            }}
          >
            {isAlertMessage.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
